import { Tabs, Row, Col, Button, Form, notification, Modal } from "antd";
import { locdau, validPath } from "../../../../helpers";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import React from "react";
import _ from "lodash";
import { RegisterContentLeft } from "../../../Components/RegisterContentLeft";
import HeaderInitClinic from "../header";
import style from "../style.module.scss";
import ClinicInfomations, {
  fieldDataPhongKham,
} from "./components/ClinicInfomations";
import ClinicServicesProvide, {
  fieldInitClinic,
} from "./components/ClinicServicesProvide";
import { getCustomers } from "../../../../duck/slices/slices/customer.slice";
import ClinicAccount, { fieldClinicAccount } from "./components/ClinicAccount";
import { common_post } from "../../../../services/apiInstances";
import { InitEnd } from "../InitEnd";
import apiServices from "../../../../config/apiServices";
import { paths } from "../../../../utils/constant";
import { Apis } from "../../../../constants";
import { WarningModal } from "../../../../components";

const tabKeys = {
  THONG_TIN_PHONG_KHAM: "THONG_TIN_PHONG_KHAM",
  THONG_TIN_DICH_VU: "THONG_TIN_DICH_VU",
  THONG_TIN_TAI_KHOAN: "THONG_TIN_TAI_KHOAN",
  KHOI_TAO_THANH_CONG: "KHOI_TAO_THANH_CONG",
};
const PrivatedInitClinic = () => {
  const navigate = useNavigate();
  const { dsPhongKham } = useSelector(getCustomers);
  const { id: paramId } = useParams();
  const [tab, setTab] = React.useState(tabKeys.THONG_TIN_PHONG_KHAM);
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);

  const setFieldData = (data) => {
    try {
      let fieldData = Object.entries(data).map(([name, value]) => {
        return { name, value };
      });
      form.setFields(fieldData);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDataChiTiet = async (ID) => {
    setLoading(true);
    try {
      const rs = await common_post(Apis.layChiTietKhachHang, { ID });
      if (rs.status === "OK") {
        setFieldData(rs.result);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // lấy chi tiết phòng khám
  React.useEffect(() => {
    if (paramId && dsPhongKham?.[0]) {
      let findPk = dsPhongKham?.find((pk) => pk.ID === paramId);
      setFieldData(findPk);
    } else if (paramId) {
      fetchDataChiTiet(paramId);
    }
  }, [paramId]);

  const stepItems = [
    {
      title: <div style={{ marginTop: -50 }}>Khởi tạo thông tin</div>,
      description: "",
    },
    {
      title: <div style={{ marginTop: -50 }}>Thông tin dịch vụ</div>,
      description: "",
    },
    {
      title: <div style={{ marginTop: -50 }}>Thông tin liên hệ</div>,
      description: "",
    },
    {
      title: (
        <div style={{ marginTop: -50, width: 200 }}>Xác nhận thành công</div>
      ),
      description: "",
    },
  ];

  const genTabs = (currTab) => {
    const keys = Object.keys(tabKeys);
    return {
      prev: keys.at(keys.indexOf(currTab) - 1),
      next: keys.at(keys.indexOf(currTab) + 1),
    };
  };

  const handleNextTabs = () => {
    setTab(genTabs(tab).next);
  };
  const handlePrevTabs = () => {
    setTab(genTabs(tab).prev);
  };

  const formFieldObject = {
    [tabKeys.THONG_TIN_PHONG_KHAM]: fieldDataPhongKham,
    [tabKeys.THONG_TIN_DICH_VU]: fieldInitClinic,
    [tabKeys.THONG_TIN_TAI_KHOAN]: undefined,
  };

  /**
   * @param {string} tab
   * @returns {Promise<boolean>}
   */
  const handleValidationFields = async (tab) => {
    let fieldForm = formFieldObject[tab];
    let formValid = await form.validateFields(
      fieldForm ? Object.values(fieldForm) : undefined
    );
    try {
      setLoading(true);
      let THONG_TIN_TAI_KHOAN = _.pick(
        formValid,
        Object.values(fieldClinicAccount)
      );
      let THONG_TIN_PHONG_KHAM = _.pick(
        formValid,
        Object.values(fieldDataPhongKham)
      );
      let thongTinDichVu = _.pick(formValid, Object.values(fieldInitClinic));
      let THONG_TIN_DICH_VU = [];
      const {
        CHAN_DOAN = [],
        DICH_VU_KHAM_BENH = [],
        XET_NGHIEM = [],
      } = thongTinDichVu;

      try {
        let pushData = (arr) => {
          if (arr?.[0]) {
            THONG_TIN_DICH_VU.push(...arr);
          }
        };
        pushData(CHAN_DOAN || []);
        pushData(DICH_VU_KHAM_BENH || []);
        pushData(XET_NGHIEM || []);
      } catch (error) {
        THONG_TIN_DICH_VU = [...CHAN_DOAN, ...DICH_VU_KHAM_BENH, ...XET_NGHIEM];
      }

      if (tab === tabKeys.THONG_TIN_TAI_KHOAN) {
        let mappingData = (obj) => {
          try {
            let arrKeys = Object.keys(obj);
            let newObj = {};

            for (const k of arrKeys) {
              let newKey = k.split(".").pop();
              newObj[newKey] = obj[k];
            }

            return newObj;
          } catch (error) {
            return obj;
          }
        };

        THONG_TIN_TAI_KHOAN = mappingData(THONG_TIN_TAI_KHOAN);
        let CO_TIEU_PHAU = form.getFieldValue("CO_TIEU_PHAU");
        let KE_THUOC = form.getFieldValue("KE_THUOC");

        THONG_TIN_TAI_KHOAN = {
          ...THONG_TIN_TAI_KHOAN,
          USERNAME:
            THONG_TIN_PHONG_KHAM.partner_code +
            "_" +
            THONG_TIN_TAI_KHOAN.USERNAME,
        };
        let data = {
          KE_THUOC,
          CO_TIEU_PHAU,
          partner_code: locdau(
            THONG_TIN_PHONG_KHAM?.partner_code
          )?.toLowerCase(),
          THONG_TIN_DICH_VU: THONG_TIN_DICH_VU?.filter?.(x => x !== "ALL"),
          THONG_TIN_PHONG_KHAM,
          THONG_TIN_TAI_KHOAN,
        };

        let rs = await common_post(Apis.khoiTaoPhongKham, data);
        if (rs && rs.status === "OK") {
          notification.success({ message: "Khởi tạo thành công" });
        } else {
          notification.error({
            message: "Có lỗi xảy ra",
            placement: "bottomLeft",
          });
        }
      }
      return true;
    } catch (error) {
      notification.error({ message: error.message, placement: "bottomLeft" });
      console.log(error);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const handleCheckFields = async () => {
    let next = true;
    if (tab === tabKeys.THONG_TIN_DICH_VU) {
      Modal.confirm({
        title: "Cảnh báo",
        content: 
          form.getFieldValue(fieldInitClinic.DICH_VU_KHAM_BENH).length > 0 
            ? "Bạn đã chọn đầy đủ loại dịch vụ của phòng khám chưa?" 
            : "Bạn chưa chọn dịch vụ nào?",
        okText: "Đồng ý",
        cancelText: "Không",
        async onOk() {
          try {
            if (next) next = await handleValidationFields(tab);
          } catch (error) {
            next = false;
          }
          if (next) handleNextTabs();
        },
        onCancel() {
          next = false;
        },
      });
    } else {
      try {
        if (next) next = await handleValidationFields(tab);
      } catch (error) {
        next = false;
      }
      if (next) handleNextTabs();
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <div className="register">
      <RegisterContentLeft />
      <div
        style={{ width: "100%", background: "#F6F8FE", position: "relative" }}
      >
        <div className={style["header"]}>
          <HeaderInitClinic
            optionSteps={stepItems}
            step={tab}
            stepProgressive={tabKeys}
          />
        </div>
        <Form form={form} layout="vertical" requiredMark={false}>
          <Tabs
            defaultActiveKey={tabKeys.THONG_TIN_PHONG_KHAM}
            activeKey={tab}
            onChange={(value) => setTab(value)}
            className={style["tab-nav"]}
          >
            <Tabs.TabPane
              tab={tabKeys.THONG_TIN_PHONG_KHAM}
              key={tabKeys.THONG_TIN_PHONG_KHAM}
            >
              <ClinicInfomations form={form} />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={tabKeys.THONG_TIN_DICH_VU}
              key={tabKeys.THONG_TIN_DICH_VU}
            >
              <ClinicServicesProvide form={form} />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={tabKeys.THONG_TIN_TAI_KHOAN}
              key={tabKeys.THONG_TIN_TAI_KHOAN}
            >
              <ClinicAccount form={form} loading={loading} />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={tabKeys.KHOI_TAO_THANH_CONG}
              key={tabKeys.KHOI_TAO_THANH_CONG}
            >
              <div style={{ paddingTop: "10vh" }}>
                {" "}
                <InitEnd
                  description={
                    <>
                      Đăng nhập để sử dụng hệ sinh thái H247:{" "}
                      <a
                        href={validPath(apiServices.CIS_WEB_UI_URL)}
                        style={{ verticalAlign: "top" }}
                      >
                        {" "}
                        Tại đây
                      </a>
                    </>
                  }
                />
              </div>
            </Tabs.TabPane>
          </Tabs>
        </Form>
        <div className={style["form-footer"]}>
          <div className={style["footer-wrapper"]}>
            <Row
              justify={"end"}
              gutter={10}
              align={"middle"}
              style={{ height: "100%" }}
            >
              {![tabKeys.KHOI_TAO_THANH_CONG].includes(tab) && (
                <Col span={3} md={6} xl={3} xxl={3} xs={4}>
                  <Button
                    block
                    type="primary"
                    ghost
                    onClick={
                      tab === tabKeys.THONG_TIN_PHONG_KHAM
                        ? handleGoBack
                        : handlePrevTabs
                    }
                  >
                    Quay lại
                  </Button>
                </Col>
              )}
              {tab !== tabKeys.KHOI_TAO_THANH_CONG && (
                <Col span={3}>
                  <Button
                    block
                    type="primary"
                    onClick={handleCheckFields}
                    loading={loading}
                  >
                    Tiếp theo
                  </Button>
                </Col>
              )}

              {tab === tabKeys.KHOI_TAO_THANH_CONG && (
                <Col>
                  <Button
                    block
                    type="primary"
                    onClick={() =>
                      navigate(
                        `/${validPath(paths.HOME)}/${validPath(paths.CUSTOMER)}`
                      )
                    }
                  >
                    Hoàn thành
                  </Button>
                </Col>
              )}
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivatedInitClinic;
